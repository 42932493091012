import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentSection from "../components/content/section";

export default class Dashboardd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}

	render() {
		return (
			<Layout>
				<SEO title="Learn The Basics of Ducat" />
				<div className="dashboard">
					<ContentSection title="Learn The Basics">
						<p>Ducat is an <b>adaptive digital currency</b> that changes its circulating supply according to the demand (price) of the currency.</p>
						<p>Ducat takes a new approach to tokenized digital currencies in that it's fully decentralized and <b>governed by an algorithm</b> instead of a centralized team or “foundation”. The volatility of the price in the open market determines the overall supply.</p>
						<p>No central authority or foundation can control the supply of Ducats because it is governed by a suite of simple smart contract algorithms and runs on top of the worlds largest open finance (DeFi) ecosystem that ensures a constant demand and use case for the currency in the evergreen digital world.</p>
						<p>The Ducat (DUCAT) economics are simple and require minimal human maintenance, <b>if any at all</b>.</p>
						<p><b>Therefore the founders have made it a priority to allocate themselves 0% of the circulating supply.</b></p>
						<p>Where other similar innovative ideas have failed due to team, investor and foundation tokens sold at 99% discount compared to market price, Ducat has made it a mission to succeed by making 100% of the minted supply distributed fairly to the cryptocurrency and DeFi community.</p>
					</ContentSection>
					<ContentSection title="Adaptive Money">
						<p>The supply of the currency is dynamic and changes according to the fluctuating market price. Supply can remain stable when there is an equilibrium if the price settles in the $1.00 - $1.03 range. But anytime that the market moves the price out of this equilibrium range the expansion and contraction continue.</p>
						<p>This means that for the early years of the currency volatility will be normal and the Ducat token holders can experience a rebase that expands the supply to increase the selling pressure or a contraction that decreases the supply to incentivize scarcity.</p>
					</ContentSection>
					<ContentSection title="Transparent Money">
						<p>The idea of a digital currency governed entirely by math and on-chain variables is a radical concept that is now possible with the advancement of blockchain finance protocols like completely decentralized exchanges, and price oracles. These technologies allow Ducat to function in an open market that cannot be stopped or slowed down by any human intervention.</p>
						<p>As users trade the coin in the open decentralized markets (Uniswap) the price of Ducat will be naturally determined by the market forces in the sell and buy-side. The market price is then automatically fed to the Uniswap oracle network that powers the on-chain algorithm that determines the supply.</p>
					</ContentSection>
					<ContentSection title="Fair Money">
						<p>Ducat is created with a completely transparent economic framework and is distributed fairly through an auction sale of 90% of the total minted supply. <b>The token has no team supply, early investors, or foundation reserve.</b></p>
						<p>The other 10% of the total minted supply is used to incentivize the initial liquidity in the Uniswap liquidity pool. This allows for early adopters to earn additional Ducats for providing valuable liquidity that is needed for the launch of a healthy market.</p>
						<p>A fair distribution of the Ducat digital currency has been the driving force of the team to get the project developed. <b>Every Ducat coin in existence from day one was purchased at auction or liquidity mined, and is fully verifiable on-chain.</b></p>
						<p><b>The Ducat token launch is designed to protect the early adopters from any unfair advantages that adaptive money supply can have for holders that did not purchase the token at the market price. Including from the team and foundation itself, since there is no supply given to anyone but to auction participants and liquidity providers.</b></p>
						<p><b>This is a radical difference that can make an adaptive money supply token carry the same hard asset qualities that Bitcoin has today.</b></p>
					</ContentSection>
				</div>
			</Layout>
		)
	}
}